(function ($) {

    "use strict";

    /* ------ Carousel script ------ */
    $('#Carousel2').owlCarousel({
        items: 4,
        itemsDesktop: [1200, 3],
        itemsDesktopSmall: [996, 2],
        itemsMobile: [768, 1]
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() >= 100) {        // If page is scrolled more than 50px
            $('#return-to-top').fadeIn(200);    // Fade in the arrow
        } else {
            $('#return-to-top').fadeOut(200);   // Else fade out the arrow
        }
    });
    $('#return-to-top').click(function () {      // When arrow is clicked
        $('body,html').animate({
            scrollTop: 0                       // Scroll to top of body
        }, 500);
    });

    /* ------ Hover Effect script ------ */
    $(".effect").on({
        mouseenter: function () {
            $(this).addClass('hover');
        },
        mouseleave: function () {
            $(this).removeClass('hover');
        }
    });

    /* ------ Agregar clase activa al menu ------ */

    var current = document.location.href.substr(document.location.href
            .lastIndexOf("/") + 1);
    $("ul.nav li a").each(function () {
        if ($(this).attr("href") == current || $(this).attr("href") == '') {
            $("ul.nav li a").removeClass("active");
            $(this).addClass("active");
        }
    });


    /* ------ Smooth Scrolling y Hash URL ------ */

    $('.nav li a').on('click', function () {
        $('.nav li a').removeClass('active');
        $(this).addClass('active');
    });

    $('a[href*="#"]').on("click", function () {

        $(".main-menu").toggleClass("blackbg");
        $("#myNavbar").collapse("toggle");

        var _this  = $(this);

        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top
            }, 1000);
            return false;
        }

        function histo() {
            history.pushState({}, "", _this.href);
        }

        window.location = histo();

    });


    /* ------ Contact form ------ */
    /*    $('#contactform').submit(function(){
     var action = $(this).attr('action');
     $("#message").slideUp(250,function() {
     $('#message').hide();
     $('#submit')
     .attr('disabled','disabled');
     $.post(action, {
     name: $('#name').val(),
     email: $('#email').val(),
     subject: $('#subject').val(),
     comments: $('#comments').val(),
     },
     function(data){
     document.getElementById('message').innerHTML = data;
     $('#message').slideDown(250);
     $('#contactform img.loader').fadeOut('slow',function(){$(this).remove()});
     $('#submit').removeAttr('disabled');
     if (data.match("success") != null){
     $('#contactform')[0].reset();
     }
     }
     );
     });
     return false;
     });*/


})(jQuery);


$(document).ready(function () {

    /* ------ Parallax background ------ */
    var window = $(window);
    if (window.width() > 800) {

        $('section[data-type="background"]').each(function () {
            var $bgobj = $(this);

            $(window).scroll(function () {
                var yPos = -( (window.scrollTop() - $bgobj.offset().top) / $bgobj.data('speed'));
                var coords = '50% ' + yPos + 'px';
                $bgobj.css({backgroundPosition: coords});
            });

        });
    }


});

